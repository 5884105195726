'use client';

import { CustomRenderers, Serialize } from './Serialize';

import classes from './index.module.scss';
import { clsx } from 'clsx';

export const RichText = ({
  className,
  content,
  customRenderers
}: {
  className?: string;
  content?: { [k: string]: unknown }[];
  customRenderers?: CustomRenderers;
}) => {
  if (!content) {
    return null;
  }

  return (
    <div className={clsx(classes.richText, className)}>
      <Serialize content={content} customRenderers={customRenderers} />
    </div>
  );
};
