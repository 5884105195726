import { Fragment, useRef, ReactElement } from 'react';
import useIntersection from '@/utils/useIntersection';

import classes from './index.module.scss';
import { clsx } from 'clsx';

export const Highlight = (props: {
  text?: string;
  bold?: boolean;
  className?: string;
  inlineIcon?: ReactElement;
  highlightOnHover?: boolean;
  highlight?: boolean;
  reverseIcon?: boolean;
  appearance?: 'success' | 'danger';
}) => {
  const { bold, className, text, inlineIcon: InlineIcon, reverseIcon, appearance = 'success' } = props;

  const ref = useRef(null);

  const { hasIntersected } = useIntersection({
    ref,
    rootMargin: '-75px'
  });

  if (text) {
    const words = text.trim().split(' ');

    if (Array.isArray(words) && words.length > 0) {
      return (
        <span ref={ref} className={clsx(classes.highlightWrapper, className ?? '', classes[appearance])}>
          {words.map((word, index) => {
            const isFirstWord = index === 0;
            const isLastWord = index === words.length - 1;

            return (
              <span
                key={index}
                className={clsx(classes.highlightNode, hasIntersected && classes.doHighlight, bold && classes.bold)}
              >
                <span className={classes.label}>
                  {InlineIcon && reverseIcon && isFirstWord && (
                    <span className={classes.iconWrapper}>
                      {InlineIcon}
                      &nbsp;
                    </span>
                  )}
                  {!isLastWord && (
                    <Fragment>
                      {word}
                      &nbsp;
                    </Fragment>
                  )}
                  {isLastWord && (!InlineIcon || reverseIcon) && word}
                  {isLastWord &&
                    InlineIcon &&
                    !reverseIcon && ( // the icon and the last word need to render together, to prevent the icon from widowing
                      <span className={classes.iconWrapper}>
                        {word}
                        &nbsp;
                        {InlineIcon}
                      </span>
                    )}
                </span>
              </span>
            );
          })}
        </span>
      );
    }
  }

  return null;
};
