import { ComponentType, CSSProperties } from 'react';
import { Media as MediaType } from '@/payload-types';

import { CMSLink, CMSLinkType } from '@/components/CMSLink';
import { Media } from '@/components/Media';

type RichTextUploadProps = {
  node: {
    fields?: {
      enableLink?: boolean;
      link?: Partial<CMSLinkType>;
    };
    value?: MediaType;
  };
  className?: string;
};

export const RichTextUpload = (props: RichTextUploadProps) => {
  const {
    node: { fields, value },
    className
  } = props;

  let Wrap: ComponentType<CMSLinkType> | string = 'div';

  const styles: CSSProperties = {};

  let wrapProps: CMSLinkType = {};

  if (fields?.enableLink as boolean) {
    Wrap = CMSLink;
    wrapProps = {
      ...fields?.link
    };
  }

  return (
    <div style={styles} className={className}>
      <Wrap {...wrapProps}>
        <Media source={value as MediaType} width={Number(value?.width)} height={Number(value?.height)} />
      </Wrap>
    </div>
  );
};

export default RichTextUpload;
