import { Fragment } from 'react';
import escapeHTML from 'escape-html';

import { CMSLink, Reference } from '@/components/CMSLink';
import { Highlight } from '../../Highlight';
import { Label } from '../../Label';
import { RichTextUpload } from '../Upload';
import { Video } from '../Video';
import { toKebabCase } from '@/utils/to-kebab-case';
import { applyAliases } from '@/utils/applyAliases';

export type CustomRenderers = {
  [key: string]: (args: {
    node: { [k: string]: unknown };
    Serialize: SerializeFunction;
    index?: number;
  }) => JSX.Element; // eslint-disable-line
};

type SerializeFunction = (props: {
  content?: { [k: string]: unknown }[];
  customRenderers?: CustomRenderers;
}) => JSX.Element | null;

interface TextObject {
  text: string;
}

const isText = (value: unknown): value is TextObject =>
  typeof value === 'object' && value !== null && 'text' in value && typeof (value as TextObject).text === 'string';

let linkId = 0;

const getId = (node: { [k: string]: unknown }): string | undefined => {
  if (Array.isArray(node.children)) {
    return toKebabCase(
      node.children
        .map(child => {
          if (
            typeof child === 'object' &&
            child !== null &&
            'text' in child &&
            typeof (child as { text: unknown }).text === 'string'
          ) {
            return (child as { text: string }).text;
          }
          return '';
        })
        .join('')
    );
  }
  return undefined;
};

export const Serialize: SerializeFunction = ({ content, customRenderers }) => {
  if (!Array.isArray(content)) return null;

  return (
    <Fragment>
      {content?.map((node, i) => {
        if (isText(node)) {
          node.text = applyAliases(node.text as string);
          let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />;

          if (node.bold) {
            text = <strong key={i}>{text}</strong>;
          }

          if (node.code) {
            text = <code key={i}>{text}</code>;
          }

          if (node.italic) {
            text = <em key={i}>{text}</em>;
          }

          if (node.underline) {
            // text = (
            //   <span style={{ textDecoration: 'underline' }} key={i}>
            //     {text}
            //   </span>
            // )
            text = <Highlight key={i} {...node} />;
          }

          if (node.strikethrough) {
            text = (
              <span style={{ textDecoration: 'line-through' }} key={i}>
                {text}
              </span>
            );
          }

          return <Fragment key={i}>{text}</Fragment>;
        }

        if (!node) {
          return null;
        }

        if (
          customRenderers &&
          customRenderers[node.type as string] &&
          typeof customRenderers[node.type as string] === 'function'
        ) {
          return customRenderers[node.type as string]({ node, Serialize, index: i });
        }

        const children = node.children as { [k: string]: unknown }[];

        switch (node.type) {
          case 'br':
            return <br key={i} />;
          case 'h1':
            return (
              <h1 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h1>
            );
          case 'h2':
            return (
              <h2 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h2>
            );
          case 'h3':
            return (
              <h3 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h3>
            );
          case 'h4':
            return (
              <h4 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h4>
            );
          case 'h5':
            return (
              <h5 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h5>
            );
          case 'h6':
            return (
              <h6 key={i} id={getId(node)}>
                <Serialize content={children} customRenderers={customRenderers} />
              </h6>
            );
          case 'quote':
            return (
              <blockquote key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </blockquote>
            );
          case 'ul':
            return (
              <ul key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </ul>
            );
          case 'ol':
            return (
              <ol key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </ol>
            );
          case 'li':
            return (
              <li key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </li>
            );
          case 'link':
            linkId += 1;
            return (
              <CMSLink
                key={i}
                type={node.linkType === 'internal' ? 'reference' : 'custom'}
                url={node.url as string}
                reference={node.doc as Reference}
                newTab={node?.newTab as boolean}
                id={`rte-link-${linkId}`}
              >
                <Serialize content={children} customRenderers={customRenderers} />
              </CMSLink>
            );

          case 'upload': {
            return <RichTextUpload key={i} node={node} />;
          }

          case 'label':
            return (
              <Label key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </Label>
            );

          case 'video': {
            const { source, id: videoID } = node;

            if (source === 'vimeo' || source === 'youtube') {
              return <Video key={i} platform={source} id={videoID as string} />;
            }

            return null;
          }

          default:
            return (
              <p key={i}>
                <Serialize content={children} customRenderers={customRenderers} />
              </p>
            );
        }
      })}
    </Fragment>
  );
};
