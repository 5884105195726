import { VimeoPlayer } from './Vimeo';
import { YouTubePlayer } from './YouTube';

import classes from './index.module.scss';

export const Video = (props: { platform?: 'vimeo' | 'youtube'; id?: string }) => {
  const { platform = 'vimeo', id } = props;

  return (
    <div
      className={classes.videoPlayer}
      style={{
        paddingTop: '56.25%'
      }}
    >
      {platform === 'youtube' && <YouTubePlayer videoID={id} />}
      {platform === 'vimeo' && <VimeoPlayer videoID={id} />}
    </div>
  );
};
